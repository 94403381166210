<template>
  <div>
    <responsible-persons-edit></responsible-persons-edit>
  </div>
</template>

<script>
import ResponsiblePersonsEdit from "@/components/ecologist/classifiers/responsiblePersons/responsiblePersonsEdit";
export default {
  name: "responsiblePersonsEditView",
  components: { ResponsiblePersonsEdit },
};
</script>

<style scoped></style>
